<template>
    <hr class="w-full h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
    <!--hr class="w-48 h-1 mx-auto bg-gray-300 border-0 rounded md:my-10 dark:bg-gray-700"-->

    <!--div class="hidden sm:block">
        <div class="py-8">
            <div class="border-t border-gray-200 dark:border-gray-700" />
        </div>
    </!--div-->
</template>
